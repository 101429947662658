/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/1/14
 * @description
 *   messagHandler.ts of FastTradeV3
 */
import * as R from 'ramda'
import { useRouter } from 'vue-router'
import { thunkCall } from '@/common/jsBridge'

const partialGuid = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)

export const guid = () => R.join(
  '', [
    partialGuid(), R.join('-', R.times(partialGuid, 5)), R.join('', R.times(partialGuid, 2)),
  ])

export const messageToken = guid()

type RouteDest = string | -1

type RouteMode = 'push' | 'replace'

type RoutePayload = {
  dest: RouteDest;
  mode?: RouteMode;
}

export const useHandleMessage = () => {
  const router = useRouter()

  const route = (mode:RouteMode, dest: RouteDest) => {
    if (dest === -1) router.back()
    else router[mode](dest)
  }

  return (e: MessageEvent) => {
    const { token, payload } = e.data

    if (payload.action !== 'nativeCall' && token !== messageToken) return

    const { action, dest, mode = 'push' } = payload as { action: string } & RoutePayload

    switch (action) {
      // 路由
      case 'route':
        route(mode, dest)
        break
      case 'nativeCall':
        console.log(dest, '===')
        thunkCall(dest as string)()
        break
    }
  }
}
