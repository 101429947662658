
import * as R from 'ramda'
import { defineComponent, onBeforeUnmount } from 'vue'
import { messageToken, useHandleMessage } from '@/worker/messagHandler'
import PageWithHeader from '@/provider/PageWithHeader.vue'

export default defineComponent({
  name: 'Link',
  components: { PageWithHeader },
  props: {
    url: {
      type: String,
      required: true,
    },
    title: String,
  },
  setup (props) {
    const handleMessage = useHandleMessage()

    window.addEventListener('message', handleMessage)

    onBeforeUnmount(() => {
      window.removeEventListener('message', handleMessage)
    })

    return {
      messageToken,
      hyphen: R.includes('?', props.url) ? '&' : '?',
    }
  },
})
